import clsx from 'clsx'
import NextLink from 'next/link'
import React from 'react'
import { LinkType } from '../../generated/content'
import { useActivePage } from '../contexts/ActiveUrlContext'
import type { LinkResult } from '../data/content/LinkFragment'

export type LinkProps = {
	className?: string
	activeClassName?: string
	children?: React.ReactNode
	onClick?: React.MouseEventHandler<HTMLAnchorElement>
} & ({ link: LinkResult | undefined } | { href: string })

export function Link(props: LinkProps) {
	const activeUrl = useActivePage()

	if ('href' in props) {
		const isActive = activeUrl === props.href
		return (
			<NextLink
				href={props.href}
				className={clsx(props.className, isActive && props.activeClassName)}
				onClick={props.onClick}>
				{props.children}
			</NextLink>
		)
	}
	if (props.link?.externalLink || props.link?.internalLink || props.link?.anchorLink) {
		const url = (
			props.link.type === LinkType.internal && props.link.internalLink?.url
				? props.link.internalLink.url
				: props.link.type === LinkType.external
				? props.link.externalLink
				: props.link.anchorLink
		) as string

		const isActive = activeUrl === url
		return (
			<NextLink
				href={url}
				className={clsx(props.className, isActive && props.activeClassName)}
				onClick={props.onClick}>
				{props.children ?? props.link.title}
			</NextLink>
		)
	} else {
		return null
	}
}
