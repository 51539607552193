import { ReferenceRendererProps, RenderElement, RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { FunctionComponent, ReactNode, useCallback } from 'react'
import type { ContentReferenceType } from '../../generated/content'
import type { ContentBlockResult } from '../data/content/ContentBlockFragment'
import type { ContentResult } from '../data/content/ContentFragment'
import type { LinkResult } from '../data/content/LinkFragment'
import { filterNonEmpty } from '../utils/filterNonEmpty'
import { useContentRendererCopyPasteBugWorkaround } from '../utils/useContentRendererCopyPasteBugWorkaround'
import { BoxList } from './BoxList'
import { Button } from './Button'
import { CarouselWithArtworks } from './CarouselWithArtworks'
import { Chart } from './Chart'
import { ContactForm } from './ContactForm'
import { Container } from './Container'
import styles from './ContentRenderer.module.sass'
import { DefinitionList } from './DefinitionList'
import { Dropdown } from './DropDown'
import { EventList } from './EventList'
import { Gallery } from './Gallery'
import { GalleryInfo } from './GalleryInfo'
import { Image } from './Image'
import { Link } from './Link'
import { LogoList } from './LogoList'
import { NewsletterForm } from './NewsletterForm'
import { PersonCarousel } from './PersonCarousel'
import { StrategySchema } from './StrategySchema'
import { VennDiagram } from './VennDiagram'
import { Wysiwyg } from './Wysiwyg'

export interface ContentRendererProps {
	content: ContentResult
	containerDisableGutters?: boolean
}

type Block = ReferenceRendererProps<ContentBlockResult['references'][number]>

const standaloneTypes = ['reference']
const nestedTypes = ['listItem', 'anchor', 'scrollTarget']

const referenceRenderers: {
	[referenceType in ContentReferenceType]?: (block: Block) => ReactNode
} = {
	gallery({ reference }) {
		return reference.media && <Gallery mediumList={reference.media} />
	},
	image({ reference }) {
		return (
			reference.image && <Image image={reference.image} alt="" maxWidth={reference.primaryText} />
		)
	},
	dropDown({ reference }) {
		return reference.dropDownList && <Dropdown dropDownList={reference.dropDownList} />
	},
	carouselWithArtworks({ reference }) {
		return (
			reference.artworkSelection && (
				<CarouselWithArtworks
					carousel={reference.artworkSelection}
					isTitleVisible={reference.isVisible}
					link={reference.link}
				/>
			)
		)
	},
	button({ reference }) {
		return reference.link && <Button link={reference.link} />
	},
	events({ reference }) {
		return (
			reference.eventList &&
			reference.primaryText && (
				<EventList events={reference.eventList} eventType={reference.primaryText} />
			)
		)
	},
	boxes({ reference }) {
		return reference.boxList && <BoxList boxes={reference.boxList} />
	},
	newsletter({ reference }) {
		return (
			reference.newsletterForm?.localesByLocale && (
				<NewsletterForm newsletterForm={reference.newsletterForm.localesByLocale} />
			)
		)
	},
	contactForm({ reference }) {
		return (
			reference.contactForm?.localesByLocale && (
				<ContactForm contactForm={reference.contactForm.localesByLocale} />
			)
		)
	},
	chart({ reference }) {
		return reference.chart && <Chart charts={reference.chart} chartLabel={reference.primaryText} />
	},
	definitionList({ reference }) {
		return reference.definitionList && <DefinitionList definitions={reference.definitionList} />
	},
	sponsors({ reference }) {
		return reference.logoList && <LogoList logoList={reference.logoList} />
	},
	strategySchema({ reference }) {
		return reference.strategySchema && <StrategySchema strategySchema={reference.strategySchema} />
	},
	vennDiagram({ reference }) {
		return (
			reference.primaryText &&
			reference.image &&
			reference.secondaryText && (
				<VennDiagram
					primaryText={reference.primaryText}
					secondaryText={reference.secondaryText}
					image={reference.image}
				/>
			)
		)
	},
	personCarousel({ reference }) {
		const { employeeList } = reference
		return (
			employeeList && (
				<PersonCarousel
					employees={employeeList.items.map((item) => item.employee).filter(filterNonEmpty)}
					link={reference.link}
				/>
			)
		)
	},
	galleryInfo({ reference }) {
		return (
			<GalleryInfo
				primaryText={reference.primaryText}
				secondaryText={reference.secondaryText}
				image={reference.image}
				imageSecond={reference.imageSecond}
				link={reference.link}
			/>
		)
	},
}

export const ContentRenderer: FunctionComponent<ContentRendererProps> = ({
	content,
	containerDisableGutters = false,
}) => {
	const blocks = useContentRendererCopyPasteBugWorkaround(content.blocks)

	return (
		<div className={styles.wrapper}>
			<RichTextRenderer
				blocks={blocks}
				sourceField="json"
				referenceRenderers={referenceRenderers}
				renderElement={useCallback<RenderElement>(
					(element) => {
						const { type } = element.element
						if ((type as string) === 'internalAnchor') {
							return (
								<Link link={(element.reference as unknown as { link: LinkResult }).link}>
									{element.children}
								</Link>
							)
						}
						console.log({ type })
						if (nestedTypes.includes(type)) {
							return element.fallback
						}
						if (standaloneTypes.includes(type)) {
							return (
								<div
									className={clsx(
										styles.section,
										element.referenceType && styles[`is_reference_${element.referenceType}`]
									)}>
									{type !== 'reference' ||
									!element.referenceType ||
									element.referenceType in referenceRenderers ? (
										element.fallback
									) : (
										<div className={styles.notImplemented}>
											<div className={styles.notImplemented_name}>{element.referenceType}</div>
											is not yet implemented
										</div>
									)}
								</div>
							)
						}
						return (
							<Container disableGutters={containerDisableGutters}>
								<Wysiwyg>{element.fallback}</Wysiwyg>
							</Container>
						)
					},
					[containerDisableGutters]
				)}
			/>
		</div>
	)
}
