import type { ImageResult } from '../data/content/ImageFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './Image.module.sass'

export type ImageProps = {
	image: ImageResult
	alt: string
	maxWidth?: string
}

export function Image(props: ImageProps) {
	return (
		<Container size="normal">
			<div
				className={s.Image}
				style={{
					'--Image-maxWidth': props.maxWidth ? `${props.maxWidth}px` : '',
				}}>
				<ContemberImage image={props.image} layout="intrinsic" />
			</div>
		</Container>
	)
}
