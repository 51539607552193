import type { LightboxSource } from '@mangoweb/fslightbox'
import { useLightbox } from '@mangoweb/react-fslightbox'
import clsx from 'clsx'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import type { MediumListResult } from '../data/content/MediumListFragment'
import { filterNonEmpty } from '../utils/filterNonEmpty'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './Gallery.module.sass'
import { Icon } from './Icon'

export type GalleryProps = {
	mediumList: MediumListResult
}

export const Gallery: FunctionComponent<GalleryProps> = ({ mediumList }) => {
	const cardElement = useRef<HTMLDivElement>(null)
	const cardsElement = useRef<HTMLDivElement>(null)

	const items = mediumList.items
		.map((item) => {
			if (item.medium) {
				const source = item.medium.video?.src ?? item.medium.image?.url
				if (source) {
					const result: LightboxSource = {
						source,
						type: item.medium.video ? 'video' : 'image',
					}
					return result
				}
			}
		})
		.filter(filterNonEmpty)

	const lightbox = useLightbox(items, {
		onSlideActivate: (current, previous) => {
			const getSlideVideo = (slideIndex: number) => {
				const video = document.querySelector(
					`.fslightbox-absoluted.fslightbox-full-dimension.fslightbox-flex-centered:nth-child(${
						slideIndex + 1
					}) video`
				)
				if (video instanceof HTMLVideoElement) {
					return video
				}
			}

			requestAnimationFrame(() => {
				if (previous !== null) {
					getSlideVideo(previous.slideIndex)?.pause()
				}
				getSlideVideo(current.slideIndex)?.play()
			})
		},
	})

	const scroll = (direction: 1 | -1) => {
		if (cardsElement.current === null || cardElement.current === null) {
			return
		}

		const cardWidth = parseInt(getComputedStyle(cardElement.current).width.replace('px', ''))

		const scrollSlideOffset = direction * cardWidth

		cardsElement.current.scrollBy({
			left: scrollSlideOffset,
			behavior: 'smooth',
		})
	}

	const [showPreviousButton, setShowPreviousButton] = useState(false)
	const [showNextButton, setShowNextButton] = useState(false)

	const onSlideChange = () => {
		if (cardsElement.current === null) {
			return
		}
		setShowPreviousButton(true)
		setShowNextButton(true)
		if (cardsElement.current.scrollLeft === 0) {
			setShowPreviousButton(false)
		}
		if (
			cardsElement.current.scrollLeft ===
			(cardsElement.current.scrollWidth ?? 0) - (cardsElement.current.clientWidth ?? 0)
		) {
			setShowNextButton(false)
		}
	}

	useEffect(() => {
		if (cardsElement.current === null) {
			return
		}

		onSlideChange()

		window.addEventListener('resize', onSlideChange)
		return () => {
			window.removeEventListener('resize', onSlideChange)
		}
	}, [])
	return (
		<>
			<Container size="fullWidth">
				<div className={s.Arrows}>
					<button
						onClick={() => scroll(-1)}
						className={clsx(s.ArrowBackward, showPreviousButton && s.isVisible)}>
						<Icon name="arrowDown" />
					</button>
					<button
						onClick={() => scroll(1)}
						className={clsx(s.ArrowForward, showNextButton && s.isVisible)}>
						<Icon name="arrowDown" />
					</button>
				</div>
			</Container>
			<Container size="fullWidth" disableGutters>
				<div className={s.GalleryWrapper}>
					<div className={s.Gallery} ref={cardsElement} onScroll={() => onSlideChange()}>
						{mediumList?.items.map((item, i) => (
							<div
								key={item.id}
								className={clsx(s.ItemWrapper, item.medium?.youtubeEmbed && s.YoutubeEmbed)}
								ref={cardElement}>
								<div className={s.Item}>
									{item.medium?.type === 'image' && item.medium.image && (
										<div className={s.CarouselWrapper}>
											<div className={s.Image}>
												<button className={s.Button} onClick={() => lightbox?.open(i)}>
													<ContemberImage image={item.medium.image} layout="intrinsic" />
												</button>
											</div>
										</div>
									)}
									{item.medium?.type === 'video' && (
										<div className={s.CarouselWrapper}>
											<div className={s.Video}>
												<iframe
													className={clsx(s.Button, s.VideoIframe)}
													title="video"
													width="640"
													height="auto"
													src={`https://www.youtube.com/embed/${item.medium.youtubeEmbed}`}
													frameBorder="0"
												/>
											</div>
										</div>
									)}
								</div>
							</div>
						))}
						<div className={s.LastTile}></div>
						<span className={s.LastPlaceholder}></span>
					</div>
				</div>
			</Container>
		</>
	)
}
