import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { createFetcher, ValidationError } from '../../libs/next/api/jsonApiHandler'
import type { API } from '../../pages/api/v1/[handler]'
import type { NewsletterFormLocaleResult } from '../data/content/NewsletterFormLocaleFragment'
import { NewsletterFormInput } from '../forms/NewsletterSubscription/NewsletterInput'
import { validateZod } from '../validators/utils/validateZod'
import { Container } from './Container'
import { FlashMessage } from './FlashMessage'
import s from './NewsletterForm.module.sass'

export const api = createFetcher<API>('/api/v1/[handler]')

export type NewsletterFormProps = {
	newsletterForm: NewsletterFormLocaleResult
}

export function NewsletterForm(props: NewsletterFormProps) {
	const mutation = useMutation(async (formData: FormData) => {
		const data = {
			email: formData.get('email'),
			newsletterTypes: formData.getAll('newsletterType'),
		}

		const validatedData = validateZod(data, NewsletterFormInput())
		const result = await api('newsletterForm', validatedData)
		if (!result.ok) {
			throw new Error('Something went wrong')
		}
		return result
	})
	const [showFlashMessage, setShowFlashMessage] = useState(false)

	useEffect(() => {
		setShowFlashMessage(mutation.isSuccess)
	}, [mutation.isSuccess])

	const validationError = mutation.error instanceof ValidationError ? mutation.error : null

	const newsletterTypesError =
		!!validationError?.getFormErrors().fieldErrors.newsletterTypes?.length
	return (
		<div className={s.NewsletterForm}>
			<Container size="normal">
				<form
					onSubmit={(e) => {
						e.preventDefault()
						mutation.mutate(new FormData(e.currentTarget))
					}}>
					<p className={s.NewsletterTypeNote}>{props.newsletterForm.newsletterTypeNote}</p>
					{props.newsletterForm.items && (
						<div className={s.CheckboxWrapper}>
							{props.newsletterForm.items.map((item) => (
								<label key={item.id} className={s.CheckboxLabel}>
									<input
										type="checkbox"
										name="newsletterType"
										defaultChecked
										value={item.id}
										className={s.Checkbox}
									/>
									<p className={s.CheckboxText}>{item.title}</p>
								</label>
							))}
							{newsletterTypesError && (
								<p className={s.ErrorMessage}>
									* {props.newsletterForm.newsletterTypeErrorMessage}
								</p>
							)}
						</div>
					)}
					<label className={s.EmailLabel}>
						<p className={s.EmailLabelText}>{props.newsletterForm.email}</p>
						<input type="email" name="email" className={s.Email} required />
					</label>
					<p className={s.EmailNote}>{props.newsletterForm.emailNote}</p>
					<button className={s.Button} type="submit">
						{props.newsletterForm.submit}
					</button>
				</form>
				{!!(mutation.error && !newsletterTypesError) && (
					<p className={s.ErrorMessage}>* {props.newsletterForm.genericErrorMessage}</p>
				)}
				{showFlashMessage && (
					<FlashMessage
						succesMessage={props.newsletterForm.successMessage}
						onDissmiss={() => setShowFlashMessage(false)}
					/>
				)}
			</Container>
		</div>
	)
}
