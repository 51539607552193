import clsx from 'clsx'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import type { EmployeeResult } from '../data/content/EmployeeFragment'
import type { LinkResult } from '../data/content/LinkFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import { Link } from './Link'
import s from './PersonCarousel.module.sass'

export type PersonCarouselProps = {
	employees: EmployeeResult[]
	link?: LinkResult
}

export const PersonCarousel: FunctionComponent<PersonCarouselProps> = ({ employees, link }) => {
	const cardElement = useRef<HTMLDivElement>(null)
	const cardsElement = useRef<HTMLDivElement>(null)

	const scroll = (direction: 1 | -1) => {
		if (cardsElement.current === null || cardElement.current === null) {
			return
		}

		const cardWidth = parseInt(getComputedStyle(cardElement.current).width.replace('px', ''))

		const scrollSlideOffset = direction * cardWidth

		cardsElement.current.scrollBy({
			left: scrollSlideOffset,
			behavior: 'smooth',
		})
	}

	const [showPreviousButton, setShowPreviousButton] = useState(false)
	const [showNextButton, setShowNextButton] = useState(false)

	const onSlideChange = () => {
		if (cardsElement.current === null) {
			return
		}
		setShowPreviousButton(true)
		setShowNextButton(true)
		if (cardsElement.current.scrollLeft === 0) {
			setShowPreviousButton(false)
		}
		if (
			cardsElement.current.scrollLeft ===
			(cardsElement.current.scrollWidth ?? 0) - (cardsElement.current.clientWidth ?? 0)
		) {
			setShowNextButton(false)
		}
	}

	useEffect(() => {
		if (cardsElement.current === null) {
			return
		}

		onSlideChange()

		window.addEventListener('resize', onSlideChange)
		return () => {
			window.removeEventListener('resize', onSlideChange)
		}
	}, [])
	return (
		<div className={s.PersonCarousel}>
			<Container size="fullWidth">
				{link && (
					<Link link={link} className={s.ButtonDesktop}>
						{link.title}
					</Link>
				)}
			</Container>
			<Container size="fullWidth" disableGutters>
				<div className={s.PersonWrapper}>
					<div className={s.CarouselPeople} ref={cardsElement} onScroll={() => onSlideChange()}>
						{employees.map((item) => (
							<div key={item.id} className={s.PersonTile} ref={cardElement}>
								{item.image && (
									<div className={s.ImageWrapper}>
										<div className={s.Image}>
											<ContemberImage image={item.image} layout="intrinsic" objectFit="contain" />
										</div>
									</div>
								)}
								<div className={s.PersonNameWrapper}>
									<p className={s.PersonName}>{item.name}</p>
									<p className={s.PersonName}>{item.localesByLocale?.position}</p>
								</div>
							</div>
						))}
						<div className={s.LastPersonTile}></div>
					</div>
					<Container size="fullWidth">
						<div className={s.Arrows}>
							<button
								onClick={() => scroll(-1)}
								className={clsx(s.ArrowBackward, showPreviousButton && s.isVisible)}>
								<Icon name="arrowDown" />
							</button>
							<button
								onClick={() => scroll(1)}
								className={clsx(s.ArrowForward, showNextButton && s.isVisible)}>
								<Icon name="arrowDown" />
							</button>
						</div>
					</Container>
					<Container size="fullWidth">
						{link && (
							<Link link={link} className={s.ButtonMobile}>
								{link?.title}
							</Link>
						)}
					</Container>
				</div>
			</Container>
		</div>
	)
}
