import clsx from 'clsx'
import { Fragment, RefObject, useEffect, useState } from 'react'
import { useWindowScroll } from 'react-use'
import type { HomePageLocaleResult } from '../data/content/HomePageLocaleFragment'
import { ContemberImage } from './ContemberImage'
import s from './HomePageAnimation.module.sass'
import { LogoArte } from './LogoArte'
import { LogoPro } from './LogoPro'

export type HomePageAnimationProps = {
	homePage: HomePageLocaleResult
	logoProTargetRef: RefObject<HTMLDivElement>
	logoArteTargetRef: RefObject<HTMLDivElement>
}

export function HomePageAnimation(props: HomePageAnimationProps) {
	const items = props.homePage.root?.media?.items || []
	const [activeImageIndex, setActiveImageIndex] = useState(0)

	const { y: scrollPosition } = useWindowScroll()

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setActiveImageIndex((activeImageIndex + 1) % items.length)
		}, 5000)
		return () => {
			clearTimeout(timeoutId)
		}
	}, [activeImageIndex, items.length])

	return (
		<>
			<div
				className={s.Animation}
				style={{
					'--HomePage-animation': `${scrollPosition}`,
				}}>
				<div className={s.LeadParagraphWrapper}>
					<div className={s.Pro}>
						<LogoPro targetRef={props.logoProTargetRef} />
					</div>
					<p className={s.LeadParagraphDesktop}>{props.homePage.leadParagraph}</p>
				</div>
				<div className={s.Arte}>
					<LogoArte targetRef={props.logoArteTargetRef} />
				</div>
				<p className={s.LeadParagraphMobile}>{props.homePage.leadParagraph}</p>
			</div>
			<div className={s.ImageWrapper}>
				{items.map((item, itemIndex) => (
					<Fragment key={item.id}>
						{item.medium?.type === 'image' && item.medium.image && (
							<div className={clsx(s.Image, activeImageIndex === itemIndex && s.isActive)}>
								<ContemberImage
									image={item.medium.image}
									layout="fill"
									objectFit="cover"
									sizes={`calc((100vh - 94px) / ${aspectRatio(item.medium.image)})`}
								/>
							</div>
						)}
					</Fragment>
				))}
			</div>
		</>
	)
}

function aspectRatio(image: { width?: number; height?: number }) {
	if (image.width && image.height) {
		return image.height / image.width
	}
	return 1
}
