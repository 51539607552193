import type { FunctionComponent } from 'react'
import type { BoxListResult } from '../data/content/BoxListFragment'
import s from './BoxList.module.sass'
import { BoxTile } from './BoxTile'
import { Container } from './Container'

export type BoxListProps = {
	boxes: BoxListResult
}

export const BoxList: FunctionComponent<BoxListProps> = ({ boxes }) => {
	return (
		<div className={s.BoxList}>
			<Container size="normal">
				{boxes.items.map(
					(item) =>
						item.link && (
							<BoxTile
								key={item.id}
								link={item.link}
								date={item.date}
								type={item.type}
								title={item.title}
								text={item.text}
							/>
						)
				)}
			</Container>
		</div>
	)
}
