import { useCallback, useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import styles from './Anchor.module.sass'

type AnchorProps = {
	onAnchorInView: (inView: boolean) => void
	remainChanged?: boolean
}

export default function Anchor(props: AnchorProps) {
	const { onAnchorInView, remainChanged = true } = props

	const { ref, inView } = useInView()
	const [isAboveOrInViewport, setIsAboveOrInViewport] = useState(false)

	const contentRef = useRef<HTMLDivElement | null>(null)

	const onScroll = useCallback(() => {
		if (contentRef.current) {
			const boundingBox = contentRef.current.getBoundingClientRect()
			const scrollTop = document.documentElement.scrollTop
			const anchorY = scrollTop + boundingBox.top
			setIsAboveOrInViewport(anchorY <= scrollTop + window.innerHeight)
		}
	}, [])

	useEffect(() => {
		onScroll()
		window.addEventListener('scroll', () => {
			onScroll()
		})
	}, [onScroll])

	useEffect(() => {
		if (!remainChanged || !isAboveOrInViewport || (isAboveOrInViewport && inView)) {
			onAnchorInView(inView)
		}
	}, [inView, isAboveOrInViewport, onAnchorInView, remainChanged])

	return (
		<div className={styles.wrapper} ref={ref}>
			<div ref={contentRef}></div>
		</div>
	)
}
