import { CSSProperties, FunctionComponent, RefObject, useEffect, useRef, useState } from 'react'
import { Icon } from './Icon'
import styles from './LogoArte.module.sass'

export interface LogoArteProps {
	targetRef: RefObject<HTMLDivElement>
}

export const LogoArte: FunctionComponent<LogoArteProps> = ({ targetRef }) => {
	const originRef = useRef<HTMLDivElement>(null)
	const [transform, setTransform] = useState({
		x: 0,
		y: 0,
		scale: 1,
	})
	useEffect(() => {
		const recalculatePosition = () => {
			const origin = originRef.current
			const target = targetRef.current
			if (!target || !origin) {
				return
			}
			const originRect = origin.getBoundingClientRect()
			const targetRect = target.getBoundingClientRect()
			const progress =
				1 -
				Math.pow(
					Math.max(
						0,
						(targetRect.top - window.scrollY / 40) / (targetRect.top + window.scrollY / 40)
					),
					2
				)
			setTransform({
				x:
					progress *
					(targetRect.left + targetRect.width / 2 - (originRect.left + originRect.width / 2)),
				y:
					progress *
					(targetRect.top + targetRect.height / 2 - (originRect.top + originRect.height / 2)),
				scale:
					1 +
					progress *
						(Math.min(targetRect.width / originRect.width, targetRect.height / originRect.height) -
							1),
			})
		}
		window.addEventListener('scroll', recalculatePosition, {
			passive: true,
		})
		window.addEventListener('resize', recalculatePosition, {
			passive: true,
		})

		return () => {
			window.removeEventListener('scroll', recalculatePosition)
			window.removeEventListener('resize', recalculatePosition)
		}
	}, [targetRef])

	return (
		<div className={styles.wrapper}>
			<div ref={originRef} className={styles.image}>
				<div
					className={styles.image_in}
					style={
						{
							['--LogoArte-x']: `${transform.x}px`,
							['--LogoArte-y']: `${transform.y}px`,
							['--LogoArte-scale']: `${transform.scale}`,
						} as CSSProperties
					}>
					<Icon name="arte" />
				</div>
			</div>
		</div>
	)
}
