import clsx from 'clsx'
import { useState } from 'react'
import Anchor from './Anchor'
import style from './Container.module.sass'

export interface ContainerProps {
	size?: 'normal' | 'wide' | 'fullWidth'
	disableGutters?: boolean
	children?: React.ReactNode
	disableInView?: boolean
}

export const Container: React.FunctionComponent<ContainerProps> = ({
	children,
	size = 'normal',
	disableGutters = false,
	disableInView,
}) => {
	const [containerInView, setContainerInView] = useState(false)
	return (
		<div
			className={clsx(
				style.wrapper,
				style[`view_size_${size}`],
				disableGutters && style.view_disableGutters,
				containerInView && style.isInView,
				disableInView && style.isInView
			)}>
			<div className={style.Anchor}>
				<Anchor onAnchorInView={(inView) => setContainerInView(inView)} />
			</div>
			{children}
		</div>
	)
}
