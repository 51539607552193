import type { FunctionComponent } from 'react'
import s from './BoxTile.module.sass'
import { Link } from './Link'

export type BoxTileProps = {
	link: string
	date?: string
	type?: string
	title?: string
	text?: string
}

export const BoxTile: FunctionComponent<BoxTileProps> = ({ link, date, type, title, text }) => {
	return (
		<Link href={link} className={s.BoxTile}>
			<div className={s.BoxInfo}>
				<p className={s.Date}>{date}</p>
				<p className={s.Type}>{type}</p>
			</div>
			<h3 className={s.Title}>{title}</h3>
			{(text && text.length > 300 && (
				<p className={s.LeadParagraph}>{text.substring(0, 250)}...</p>
			)) || <p className={s.LeadParagraph}>{text}</p>}
		</Link>
	)
}
