// Reexport all svg icons

export { default as arrowBig } from './arrowBig.svg'
export { default as arrowDown } from './arrowDown.svg'
export { default as arte } from './arte.svg'
export { default as cross } from './cross.svg'
export { default as go } from './go.svg'
export { default as hamburger } from './hamburger.svg'
export { default as hamburgerMobile } from './hamburgerMobile.svg'
export { default as play } from './play.svg'
export { default as plus } from './plus.svg'
export { default as pro } from './pro.svg'
