import { Fragment, RefObject } from 'react'
import type { HomePageLocaleResult } from '../data/content/HomePageLocaleFragment'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import s from './HomePage.module.sass'
import { HomePageAnimation } from './HomePageAnimation'
import { Icon } from './Icon'
import { Link } from './Link'

export type HomeProps = {
	homePage: HomePageLocaleResult
	logoProTargetRef: RefObject<HTMLDivElement>
	logoArteTargetRef: RefObject<HTMLDivElement>
}

export function HomePage(props: HomeProps) {
	return (
		<div className={s.HomePage}>
			<HomePageAnimation
				homePage={props.homePage}
				logoProTargetRef={props.logoProTargetRef}
				logoArteTargetRef={props.logoArteTargetRef}
			/>
			<Container size="wide">
				{props.homePage.branches && (
					<div className={s.Branches}>
						{props.homePage.branches.map((item) => {
							const titleWords = item.link?.title?.split(' ')
							return (
								<div key={item.id} className={s.Branch}>
									<Link link={item.link}>
										<h3 className={s.BranchTitle}>
											{titleWords?.map((word, wordIndex) => {
												if (wordIndex === titleWords.length - 1) {
													return (
														<span key={item.id} className={s.BranchTitleLastWord}>
															{word}
															<span className={s.Arrow}>
																<span className={s.ArrowIn}>
																	<Icon name="arrowDown" />
																</span>
															</span>
														</span>
													)
												}
												return <Fragment key={wordIndex}>{word} </Fragment>
											})}
										</h3>
										<p className={s.BranchLeadParagraph}>{item.leadParagraph}</p>
										<p className={s.BranchMore}>{item.more}</p>
									</Link>
								</div>
							)
						})}
					</div>
				)}
			</Container>
			{props.homePage.content && <ContentRenderer content={props.homePage.content} />}
		</div>
	)
}
