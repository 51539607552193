import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import { useRef } from 'react'
import { Artwork } from '../app/components/Artwork'
import { ArtworkSelection } from '../app/components/ArtworkSelection'
import type { Breadcrumb } from '../app/components/Breadcrumbs'
import { ContactPage } from '../app/components/ContactPage'
import { Event } from '../app/components/Event'
import { Footer } from '../app/components/Footer'
import { Header } from '../app/components/Header'
import { HomePage } from '../app/components/HomePage'
import { Page } from '../app/components/Page'
import { combineUrl } from '../app/data/combineUrl'
import { ArtworkLocaleFragment } from '../app/data/content/ArtworkLocaleFragment'
import { ArtworkSelectionFragment } from '../app/data/content/ArtworkSelectionFragment'
import { ContactPageLocaleFragment } from '../app/data/content/ContactPageLocaleFragment'
import { EventLocaleFragment } from '../app/data/content/EventLocaleFragment'
import { FooterLocaleFragment } from '../app/data/content/FooterLocaleFragment'
import { HeaderLocaleFragment } from '../app/data/content/HeaderLocaleFragment'
import { HomePageLocaleFragment } from '../app/data/content/HomePageLocaleFragment'
import { PageLocaleFragment } from '../app/data/content/PageLocaleFragment'
import { RedirectFragment } from '../app/data/content/RedirectFragment'
import { filterNonEmpty } from '../app/utils/filterNonEmpty'
import { One, Thunder } from '../generated/content'
import {
	RespondWithNotFound,
	RespondWithRedirect,
	serverPropsHandler,
} from '../libs/next/api/handlers'
import { getZeusConnection } from '../libs/next/loaders/graphql'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage(props: CommonPageProps) {
	const header = props.dataFromContember.getHeaderLocale
	const { artwork, artworkSelection, contactPage, event, homePage, page } =
		props.dataFromContember.getLinkable ?? {}
	const footer = props.dataFromContember.getFooterLocale

	const pageLocales = [homePage, event, artwork, artworkSelection, contactPage, page].filter(
		filterNonEmpty
	)[0]

	const logoProTargetRef = useRef<HTMLDivElement>(null)
	const logoArteTargetRef = useRef<HTMLDivElement>(null)

	return (
		<>
			<Head>
				<title>Pro arte, investiční fond</title>
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
				<meta name="msapplication-TileColor" content="#ffffff" />
				<meta name="theme-color" content="#ffffff" />
			</Head>

			{header && (
				<Header
					header={header}
					translations={pageLocales.root?.locales ?? []}
					isHomePage={Boolean(homePage)}
					logoProTargetRef={logoProTargetRef}
					logoArteTargetRef={logoArteTargetRef}
					homePageLink={props.dataFromContember.getHomePageLocale?.link?.url}
					breadcrumbs={props.breadcrumbs}
				/>
			)}
			{homePage && (
				<HomePage
					homePage={homePage}
					logoProTargetRef={logoProTargetRef}
					logoArteTargetRef={logoArteTargetRef}
				/>
			)}
			{artwork && <Artwork artwork={artwork} />}
			{artworkSelection?.root && <ArtworkSelection artworkSelection={artworkSelection.root} />}
			{event && <Event event={event} />}
			{contactPage && <ContactPage contactPage={contactPage} />}
			{page && <Page page={page} />}
			{footer && <Footer footer={footer} isContactPage={Boolean(contactPage)} />}

			{/* <section>
				<h1>
					Props from <code>getServerSideProps</code>
				</h1>
				<Dump data={props} />
			</section>

			<section>
				<h1>
					Server-side number sum with <code>/api/v1/[handler]</code>
				</h1>
				<SumForm />
			</section> */}
		</>
	)
}

export const getServerSideProps = serverPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	// const urlInfo = combineUrl(context)

	const { path, locale } = combineUrl(context)

	// const url = `${urlInfo.path}`

	const dataFromContember = await content.query({
		// ...SiteFragment(urlInfo.locale),

		getHeaderLocale: [
			{
				by: {
					root: { unique: One.One },
					locale: { code: locale },
				},
			},
			HeaderLocaleFragment(),
		],
		getHomePageLocale: [
			{
				by: {
					root: { unique: One.One },
					locale: { code: locale },
				},
			},
			{
				link: [{}, { url: true }],
				title: true,
			},
		],

		getLinkable: [
			{ by: { url: path } },
			{
				id: true,
				url: true,
				homePage: [{}, HomePageLocaleFragment(locale)],
				page: [{}, PageLocaleFragment(locale)],
				event: [{}, EventLocaleFragment(locale)],
				artwork: [{}, ArtworkLocaleFragment(locale)],
				artworkSelection: [
					{},
					{
						title: true,
						link: [
							{},
							{
								url: true,
							},
						],
						root: [{}, ArtworkSelectionFragment(locale)],
					},
				],
				contactPage: [{}, ContactPageLocaleFragment()],
				redirect: [{}, RedirectFragment()],
			},
		],
		listLinkable: [{}, { redirect: [{}, RedirectFragment()] }],
		getFooterLocale: [
			{
				by: {
					root: { unique: One.One },
					locale: { code: locale },
				},
			},
			FooterLocaleFragment(),
		],
	})

	const redirectUrl = dataFromContember.getLinkable?.redirect?.target?.url

	if (redirectUrl) {
		throw new RespondWithRedirect({ permanent: false, destination: redirectUrl })
	}

	const possiblePages = [
		dataFromContember.getLinkable?.artwork,
		dataFromContember.getLinkable?.artworkSelection,
		dataFromContember.getLinkable?.contactPage,
		dataFromContember.getLinkable?.event,
		dataFromContember.getLinkable?.homePage,
		dataFromContember.getLinkable?.page,
	]

	if (possiblePages.every((page) => !page)) {
		throw new RespondWithNotFound('Blog post not found')
	}

	const breadcrumbs: Breadcrumb[] = []

	if (dataFromContember.getHomePageLocale) {
		breadcrumbs.push({
			title: dataFromContember.getHomePageLocale.title ?? '',
			url: dataFromContember.getHomePageLocale.link?.url,
		})
	}

	if (dataFromContember.getLinkable?.artwork) {
		breadcrumbs.push({
			title: `${dataFromContember.getHeaderLocale?.artworkBreadcrumbParentLink?.page?.title}`,
			url: `${dataFromContember.getHeaderLocale?.artworkBreadcrumbParentLink?.url}`,
		})
		breadcrumbs.push({
			title:
				`${dataFromContember.getLinkable.artwork.title}, ${dataFromContember.getLinkable.artwork.artist}` ??
				'',
			url: dataFromContember.getLinkable.artwork?.link?.url ?? '',
		})
	} else {
		const firstPossiblePage = possiblePages.find((page) => Boolean(page))

		if (firstPossiblePage) {
			breadcrumbs.push({
				title: firstPossiblePage.title ?? '',
				url: firstPossiblePage?.link?.url ?? '',
			})
		}
	}

	return {
		props: {
			path,
			locale,
			dataFromContember,
			breadcrumbs,
			serverTime: new Date().toString(),
		},
	}
})
