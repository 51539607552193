import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { EventLocaleResult } from '../data/content/EventLocaleFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { ContentRenderer } from './ContentRenderer'
import s from './Event.module.sass'

export type EventProps = {
	event: EventLocaleResult
}

export function Event(props: EventProps) {
	return (
		<div className={clsx(s.Event, props.event.isDark && s.isDark)}>
			<div className={s.ImageTextWrapper}>
				{props.event.image && (
					<div className={s.Image}>
						<ContemberImage image={props.event.image} layout="fill" objectFit="cover" />
					</div>
				)}
				<div className={s.Lines}>
					{new Array(10).fill(null).map((_, i) => (
						<div className={s.Line} key={i}></div>
					))}
				</div>
				<h1 className={s.Title}>{props.event.title}</h1>
			</div>

			<Container size="normal">
				<p className={s.Date}>{props.event.date}</p>
			</Container>
			<div className={s.Border}></div>
			<Container size="normal">
				<div className={s.VenueWrapper}>
					{props.event.venue && (
						<div className={s.Venue}>
							<RichTextRenderer source={props.event.venue} />
						</div>
					)}
					{props.event.venueNote && (
						<div className={s.VenueNote}>
							<RichTextRenderer source={props.event.venueNote} />
						</div>
					)}
				</div>
			</Container>
			<div className={s.Border}></div>
			<Container size="normal">
				<p className={s.LeadParagraph}>{props.event.leadParagraph}</p>
			</Container>
			{props.event.content && <ContentRenderer content={props.event.content} />}
		</div>
	)
}
