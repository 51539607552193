import type { PageLocaleResult } from '../data/content/PageLocaleFragment'
import { Container } from './Container'
import { ContentRenderer } from './ContentRenderer'
import s from './Page.module.sass'

export type PageProps = {
	page: PageLocaleResult
}

export function Page(props: PageProps) {
	return (
		<div className={s.Page}>
			<Container size="wide">
				<h1 className={s.Title}>{props.page.title}</h1>
			</Container>
			{props.page.content && <ContentRenderer content={props.page.content} />}
		</div>
	)
}
