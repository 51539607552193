import type { FunctionComponent } from 'react'
import React from 'react'
import type { LinkResult } from '../data/content/LinkFragment'
import s from './Button.module.sass'
import { Icon } from './Icon'
import { Link } from './Link'

export type ButtonProps = {
	link: LinkResult
}

export const Button: FunctionComponent<ButtonProps> = ({ link }) => {
	const titleWords = link.title?.split(' ')
	return (
		<div className={s.Button}>
			<Link link={link}>
				<p className={s.Link}>
					{titleWords?.map((word, wordIndex) => {
						if (wordIndex === titleWords.length - 1) {
							return (
								<span key={link.title} className={s.TitleLastWord}>
									{word}
									<span className={s.Arrow}>
										<span className={s.ArrowIn}>
											<Icon name="arrowDown" />
										</span>
									</span>
								</span>
							)
						}
						return <React.Fragment key={wordIndex}>{word} </React.Fragment>
					})}
				</p>
			</Link>
		</div>
	)
}
