import type { FunctionComponent } from 'react'
import type { StrategySchemaResult } from '../data/content/StrategySchemaFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import s from './StrategySchema.module.sass'

export type StrategySchemaProps = {
	strategySchema: StrategySchemaResult
}

export const StrategySchema: FunctionComponent<StrategySchemaProps> = ({ strategySchema }) => {
	return (
		<div className={s.StrategySchema}>
			<Container size="normal">
				<div className={s.Section}>
					<p className={s.Input}>{strategySchema.inputFirst}</p>
					<p className={s.Input}>{strategySchema.inputSecond}</p>
					<p className={s.Input}>{strategySchema.inputThird}</p>
				</div>
				<div className={s.Arrows}>
					<div className={s.ArrowLeft}>
						<Icon name="arrowBig" />
					</div>
					<div className={s.ArrowRight}>
						<Icon name="arrowBig" />
					</div>
				</div>
				<div className={s.Section}>
					<div className={s.ItemsFirst}>
						{strategySchema.itemsFirst?.items.map((item) => (
							<div key={item.id}>
								<p className={s.Text}>{item.text}</p>
							</div>
						))}
					</div>
					{strategySchema.logo && (
						<div className={s.Image}>
							<ContemberImage image={strategySchema.logo} />
						</div>
					)}
					<div className={s.ItemsSecond}>
						{strategySchema.itemsSecond?.items.map((item) => (
							<div key={item.id}>
								<p className={s.Text}>{item.text}</p>
							</div>
						))}
					</div>
				</div>
				<div className={s.Arrows}>
					<div className={s.ArrowLeft}>
						<Icon name="arrowBig" />
					</div>
					<div className={s.ArrowRight}>
						<Icon name="arrowBig" />
					</div>
				</div>
				<div className={s.Section}>
					<p className={s.Output}>{strategySchema.outputFirst}</p>
					<p className={s.Output}>{strategySchema.outputSecond}</p>
					<p className={s.Output}>{strategySchema.outputThird}</p>
				</div>
			</Container>
		</div>
	)
}
