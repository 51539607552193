import clsx from 'clsx'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import type { ChartResult } from '../data/content/ChartFragment'
import type { ImageResult } from '../data/content/ImageFragment'
import { filterNonEmpty } from '../utils/filterNonEmpty'
import s from './Chart.module.sass'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'

export type ChartProps = {
	charts: ChartResult
	chartLabel?: string
}

export const Chart: FunctionComponent<ChartProps> = ({ charts, chartLabel }) => {
	const columnHighiestValue = Math.max(
		...charts.items.map((item) => item.number).filter(filterNonEmpty)
	)
	const total = charts.items.reduce((partialSum, a) => partialSum + (a.number ?? 0), 0)
	let currentOffset = 0

	const cardElement = useRef<HTMLDivElement>(null)
	const cardsElement = useRef<HTMLDivElement>(null)

	const scroll = (direction: 1 | -1) => {
		if (cardsElement.current === null || cardElement.current === null) {
			return
		}

		const cardWidth = parseInt(getComputedStyle(cardElement.current).width.replace('px', ''))

		const scrollSlideOffset = direction * cardWidth

		cardsElement.current.scrollBy({
			left: scrollSlideOffset,
			behavior: 'smooth',
		})
	}

	const [showPreviousButton, setShowPreviousButton] = useState(false)
	const [showNextButton, setShowNextButton] = useState(false)

	const onSlideChange = () => {
		if (cardsElement.current === null) {
			return
		}
		setShowPreviousButton(true)
		setShowNextButton(true)
		if (cardsElement.current.scrollLeft === 0) {
			setShowPreviousButton(false)
		}
		if (
			cardsElement.current.scrollLeft ===
			(cardsElement.current.scrollWidth ?? 0) - (cardsElement.current.clientWidth ?? 0)
		) {
			setShowNextButton(false)
		}
	}

	useEffect(() => {
		if (cardsElement.current === null) {
			return
		}

		onSlideChange()

		window.addEventListener('resize', onSlideChange)
		return () => {
			window.removeEventListener('resize', onSlideChange)
		}
	}, [])
	return (
		<div className={s.Chart}>
			<Container size="normal" disableGutters>
				{charts.type === 'pie' && (
					<div>
						<div className={s.PieGraph}>
							{charts.items.map((item) => {
								const fraction = (item?.number || 0) / total
								const rotationDegrees = (currentOffset / total) * 360
								const textRotationDegrees =
									((currentOffset + (item?.number || 0) / 2) / total) * 360
								currentOffset += item?.number || 0
								return (
									<div key={item.id}>
										<div
											className={clsx(
												s.PieGraphFragment,
												angleToPositionClassName(textRotationDegrees)
											)}
											style={{
												'--PieGraph-rotationDegrees': rotationDegrees,
												'--PieGraph-textRotationDegrees': textRotationDegrees,
											}}>
											<ChartSvg
												fraction={fraction}
												title={item.text ?? ''}
												number={item.number ?? 0}
												image={item.image}
											/>
										</div>
									</div>
								)
							})}
						</div>
						<Container size="normal">
							<div className={s.MobileTextsWrapper}>
								{charts.items.map((item) => (
									<div key={item.id} className={s.MobileTexts}>
										<p className={s.MobileText}>{item.text}</p>
										<p className={s.MobileNumber}>{item.number}%</p>
									</div>
								))}
							</div>
						</Container>
					</div>
				)}
				{charts.type === 'column' && (
					<div className={s.ChartColumnWrapper}>
						<div className={s.ChartColumn} ref={cardsElement} onScroll={() => onSlideChange()}>
							{charts.items.map((item) => (
								<div
									key={item.id}
									className={s.ChartColumnLine}
									ref={cardElement}
									style={{
										'--ChartColumn-heightFraction': (item.number ?? 0) / columnHighiestValue,
									}}>
									<p className={s.ColumnInfoText}>{item.number} Kč</p>
									<div className={s.Lines}>
										{/* {new Array(6).fill(null).map((_, i) => ( */}
										{/* <div className={s.Line}></div> */}
										{/* ))} */}
									</div>
									{item.date && (
										<p className={s.Date}>
											{new Intl.DateTimeFormat('cs', {
												month: 'numeric',
												year: 'numeric',
											}).format(new Date(item.date))}
										</p>
									)}
								</div>
							))}
						</div>
						<Container size="normal">
							<p className={s.ChartNote}>{chartLabel}</p>
						</Container>
						<Container size="normal">
							<div className={s.Arrows}>
								<button
									onClick={() => scroll(-1)}
									className={clsx(s.ArrowBackward, showPreviousButton && s.isVisible)}>
									<Icon name="arrowDown" />
								</button>
								<button
									onClick={() => scroll(1)}
									className={clsx(s.ArrowForward, showNextButton && s.isVisible)}>
									<Icon name="arrowDown" />
								</button>
							</div>
						</Container>
					</div>
				)}
			</Container>
		</div>
	)
}

const angleToPositionClassName = (angle: number) => {
	if (angle < 90) {
		return s.isPositionRightBottom
	}
	if (angle < 180) {
		return s.isPositionLeftBottom
	}
	if (angle < 270) {
		return s.isPositionLeftTop
	}
	return s.isPositionRightTop
}

export const ChartSvg: FunctionComponent<{
	fraction: number
	title: string
	number: number
	image?: ImageResult
}> = ({ fraction, title, number, image }) => {
	const fractionWithGap = fraction - 5 / 360 // might break with really small fraction numbers
	return (
		<div className={s.CirclesWrapper}>
			<div className={s.CirclesText}>
				<div className={s.CirclesTextIn}>
					<p className={s.CircleTitle}>{title}</p>
					<p>{number} %</p>
					<div className={s.CircleHover}>{image && <ContemberImage image={image} />}</div>
				</div>
			</div>

			<svg height="600" width="600" viewBox="0 0 600 600" fill="transparent" className={s.Circles}>
				<circle
					stroke="currentColor"
					r="299"
					cx="300"
					cy="300"
					// stroke="#231F20"
					strokeWidth="1"
					strokeDasharray={1877.913330078125}
					strokeDashoffset={1877.913330078125 * (1 - fractionWithGap)}
				/>
				<circle
					stroke="currentColor"
					r="289"
					cx="300"
					cy="300"
					// stroke="#231F20"
					strokeWidth="1"
					strokeDasharray={1815.1068115234375}
					strokeDashoffset={1815.1068115234375 * (1 - fractionWithGap)}
				/>
				<circle
					stroke="currentColor"
					r="279"
					cx="300"
					cy="300"
					// stroke="#231F20"
					strokeWidth="1"
					strokeDasharray={1752.3004150390625}
					strokeDashoffset={1752.3004150390625 * (1 - fractionWithGap)}
				/>
			</svg>
		</div>
	)
}
