import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import type { DefinitionListResult } from '../data/content/DefinitionListFragment'
import { Container } from './Container'
import s from './DefinitionList.module.sass'

export type DefinitionListProps = {
	definitions: DefinitionListResult
}

export const DefinitionList: FunctionComponent<DefinitionListProps> = ({ definitions }) => {
	return (
		// <div className={s.DefinitionList}>
		<Container size="normal">
			{definitions.items.map((item) => (
				<div key={item.id} className={s.Definition}>
					<p className={s.Key}>{item.key}</p>
					{item.value && (
						<span className={s.Value}>
							<RichTextRenderer source={item.value} />
						</span>
					)}
				</div>
			))}
		</Container>
		// </div>
	)
}
