import type { ArtworkSelectionResult } from '../data/content/ArtworkSelectionFragment'
import s from './ArtworkSelection.module.sass'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'

export type ArtworkSelectionProps = {
	artworkSelection: ArtworkSelectionResult
}

export function ArtworkSelection(props: ArtworkSelectionProps) {
	return (
		<div className={s.ArtworkSelection}>
			<Container size="normal">
				<h1 className={s.Title}>{props.artworkSelection.localesByLocale?.title}</h1>
				<div className={s.Artworks}>
					{props.artworkSelection.artworks?.items.map((item) => (
						<div key={item.id} className={s.Artwork}>
							{item.artwork?.image && <ContemberImage image={item.artwork.image} />}
							<p className={s.ArtworkTitle}>
								{item.artwork?.localesByLocale?.artist &&
									item.artwork?.localesByLocale?.artist + ', '}
								{item.artwork?.localesByLocale?.title}
							</p>
						</div>
					))}
				</div>
			</Container>
		</div>
	)
}
