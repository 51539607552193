import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import style from './Wysiwyg.module.sass'

export type WysiwygProps = {
	source?: string
	children?: React.ReactNode
}

export const Wysiwyg: FunctionComponent<WysiwygProps> = ({ source, children }) => {
	return (
		<div className={style.wrapper}>{source ? <RichTextRenderer source={source} /> : children}</div>
	)
}
