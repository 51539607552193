import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { FooterLocaleResult } from '../data/content/FooterLocaleFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './Footer.module.sass'
import { Link } from './Link'

export type FooterProps = {
	footer: FooterLocaleResult
	isContactPage?: boolean
}

export function Footer(props: FooterProps) {
	return (
		<Container size="fullWidth" disableInView>
			<div className={clsx(s.Footer, props.isContactPage && s.isContactPage)}>
				<div className={s.Info}>
					{props.footer.address && (
						<div className={s.Address}>
							<RichTextRenderer source={props.footer.address} />
						</div>
					)}

					<div className={s.Contacts}>
						{props.footer.phone && (
							<Link href={`tel:to${props.footer.phone}`}>{props.footer.phone}</Link>
						)}
						{props.footer.email && (
							<Link href={`mailto:${props.footer.email}`}>{props.footer.email}</Link>
						)}
					</div>
					<div className={s.Socials}>
						{props.footer.youtube && (
							<Link className={s.Social} href={props.footer.youtube}>
								Youtube
							</Link>
						)}
						{props.footer.instagram && (
							<Link className={s.Social} href={props.footer.instagram}>
								Instagram
							</Link>
						)}
						{props.footer.facebook && (
							<Link className={s.Social} href={props.footer.facebook}>
								Facebook
							</Link>
						)}
						{props.footer.linkedIn && (
							<Link className={s.Social} href={props.footer.linkedIn}>
								LinkedIn
							</Link>
						)}
					</div>
				</div>
				{props.footer.root?.logo && (
					<div className={s.Logo}>
						<ContemberImage image={props.footer.root?.logo} />
					</div>
				)}

				<div className={s.MadeBy}>
					<div className={s.GraphicDesigners}>
						<p className={s.Note}>{props.footer.graphicDesign}</p>
						{props.footer.graphicDesigners.map((item) => (
							<div key={item.id} className={s.GraphicDesigner}>
								<p className={s.Ampersand}>&</p>
								<Link link={item.link} className={s.Link} />
							</div>
						))}
					</div>
					<div className={s.Dot}></div>
					<div className={s.Photographers}>
						<p className={s.Note}>{props.footer.photography}</p>
						{props.footer.photographers.map((item) => (
							<div key={item.id} className={s.Photographer}>
								<p className={s.Ampersand}>&</p>
								<Link link={item.link} className={s.Link} />
							</div>
						))}
					</div>
					<div className={s.Dot}></div>
					<div className={s.Development}>
						<p className={s.Note}>{props.footer.development}</p>
						<Link link={props.footer.developerLink} className={s.Link} />
					</div>
				</div>
			</div>
		</Container>
	)
}
