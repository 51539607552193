import clsx from 'clsx'
import Image from 'next/legacy/image'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { z } from 'zod'
import { createFetcher } from '../../libs/next/api/jsonApiHandler'
import type { API } from '../../pages/api/v1/[handler]'
import type { ContactFormLocaleResult } from '../data/content/ContactFormLocaleFragment'
import { ContactFormInput } from '../forms/ContactSubscription/ContactInput'
import { validateZod } from '../validators/utils/validateZod'
import s from './ContactForm.module.sass'
import { Container } from './Container'
import { FlashMessage } from './FlashMessage'
import { Icon } from './Icon'

export const api = createFetcher<API>('/api/v1/[handler]')

export type ContactFormProps = {
	contactForm: ContactFormLocaleResult
}

export function ContactForm(props: ContactFormProps) {
	const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null)

	const mutation = useMutation(async (formData: FormData) => {
		const file = z.instanceof(File).parse(formData.get('file'))

		const data = {
			name: formData.get('name'),
			phone: formData.get('phone'),
			email: formData.get('email'),
			message: formData.get('message'),
			fileType: file?.type,
			size: file?.size,
		}

		const validatedData = validateZod(data, ContactFormInput())
		const result = await api('contactForm', validatedData)
		if (!result.ok) {
			throw new Error('Something went wrong')
		}

		if (result.upload) {
			const { url, headers, method } = result.upload

			const response = fetch(url, {
				method,
				headers: Object.fromEntries(headers.map((header) => [header.key, header.value])),
				body: formData.get('file'),
			})
			if (!(await response).ok) {
				throw new Error('Failed to upload file.')
			}
		}

		return result
	})
	const [showFlashMessage, setShowFlashMessage] = useState(false)

	useEffect(() => {
		setShowFlashMessage(mutation.isSuccess)
	}, [mutation.isSuccess])

	// const validationError = mutation.error instanceof ValidationError ? mutation.error : null

	// const contactTypesError = !!validationError?.getFormErrors().fieldErrors.contactTypes?.length
	return (
		<div className={s.ContactForm}>
			<Container size="normal">
				<form
					onSubmit={(e) => {
						e.preventDefault()
						mutation.mutate(new FormData(e.currentTarget))
					}}>
					<label className={s.InputLabel}>
						<p className={s.InputLabelText}>{props.contactForm.name}</p>
						<input type="text" name="name" className={s.Input} required />
					</label>
					<label className={s.InputLabel}>
						<p className={s.InputLabelText}>{props.contactForm.phone}</p>
						<input type="tel" name="phone" className={s.Input} required />
					</label>
					<label className={s.InputLabel}>
						<p className={s.InputLabelText}>{props.contactForm.email}</p>
						<input type="email" name="email" className={s.Input} required />
					</label>
					<label className={s.InputLabel}>
						<p className={s.InputLabelText}>{props.contactForm.message}</p>
						<textarea rows={7} name="message" className={s.Input} required />
					</label>
					<label className={s.InputLabel}>
						<p className={s.InputLabelText}>{props.contactForm.file}</p>
						<div className={clsx(s.Input, s.InputFileWrapper)}>
							<input
								type="file"
								name="file"
								className={s.InputFile}
								onChange={(e) => {
									const file = e.target.files?.item(0)
									if (file) {
										setImagePreviewUrl(URL.createObjectURL(file))
									}
								}}
							/>
							<div className={s.Plus}>
								<Icon name="plus" />
							</div>
							<div className={s.Image}>
								{imagePreviewUrl && (
									<Image
										src={imagePreviewUrl}
										alt="Soubor k uploadu"
										width="150"
										height="150"
										objectFit="contain"
									/>
								)}
							</div>
						</div>
					</label>
					{/* {props.contactForm.items && (
						<div className={s.CheckboxWrapper}>
							{props.contactForm.items.map((item) => (
								<label key={item.id} className={s.CheckboxLabel}>
									<input
										type="checkbox"
										name="contactType"
										value={item.id}
										className={s.Checkbox}
									/>
									<p className={s.CheckboxText}>{item.title}</p>
								</label>
							))}
							{contactTypesError && (
								<p className={s.ErrorMessage}>{props.contactForm.contactTypeErrorMessage}</p>
							)}
						</div>
					)} */}

					<button className={s.Button} type="submit">
						{props.contactForm.submit}
					</button>
				</form>
				{/* {!!(mutation.error && !contactTypesError) && (
					<p className={s.ErrorMessage}>* {props.contactForm.genericErrorMessage}</p>
				)} */}
				{showFlashMessage && (
					<FlashMessage
						succesMessage={props.contactForm.successMessage}
						onDissmiss={() => setShowFlashMessage(false)}
					/>
				)}
			</Container>
		</div>
	)
}
