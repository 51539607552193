import type { NewsletterFormLocaleResult } from '../data/content/NewsletterFormLocaleFragment'
import s from './FlashMessage.module.sass'

type FlashMessageProps = {
	succesMessage: NewsletterFormLocaleResult['successMessage']
	onDissmiss?: () => void
}

export const FlashMessage = (props: FlashMessageProps) => {
	const { onDissmiss } = props

	return (
		<div className={s.FlashMessage} onAnimationEnd={onDissmiss}>
			<p>{props.succesMessage}</p>
		</div>
	)
}
