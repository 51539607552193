import { RichTextRenderer } from '@contember/react-client'
import type { FunctionComponent } from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import type { LinkResult } from '../data/content/LinkFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './GalleryInfo.module.sass'
import { Link } from './Link'

export type GalleryInfoProps = {
	primaryText?: string
	secondaryText?: string
	image?: ImageResult
	imageSecond?: ImageResult
	link?: LinkResult
}

export const GalleryInfo: FunctionComponent<GalleryInfoProps> = ({
	primaryText,
	secondaryText,
	image,
	imageSecond,
	link,
}) => {
	return (
		<Container size="normal">
			<div className={s.GalleryInfo}>
				<div className={s.Images}>
					{image && (
						<div className={s.ImageWrapper}>
							<div className={s.Image}>
								<ContemberImage image={image} layout="intrinsic" />
							</div>
						</div>
					)}
					{imageSecond && (
						<div className={s.ImageWrapper}>
							<div className={s.Image}>
								<ContemberImage image={imageSecond} layout="intrinsic" />
							</div>
						</div>
					)}
				</div>
				<div className={s.Texts}>
					{primaryText && (
						<p className={s.TextFirst}>
							<RichTextRenderer source={primaryText} />
						</p>
					)}
					{secondaryText && (
						<p className={s.TextSecond}>
							<RichTextRenderer source={secondaryText} />
						</p>
					)}
				</div>
				<Link className={s.Link} link={link} />
			</div>
		</Container>
	)
}
