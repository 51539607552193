import type { FunctionComponent } from 'react'
import type { ImageResult } from '../data/content/ImageFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './VennDiagram.module.sass'

export type VennDiagramProps = {
	primaryText: string
	image: ImageResult
	secondaryText: string
}

export const VennDiagram: FunctionComponent<VennDiagramProps> = ({
	primaryText,
	image,
	secondaryText,
}) => {
	return (
		<Container size="normal">
			<div className={s.VennDiagram}>
				<p className={s.Text}>{primaryText}</p>
				<div className={s.Logo}>
					<ContemberImage image={image} layout="intrinsic" />
				</div>
				<p className={s.Text}>{secondaryText}</p>
			</div>
		</Container>
	)
}
