import clsx from 'clsx'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import type { ArtworkSelectionResult } from '../data/content/ArtworkSelectionFragment'
import type { LinkResult } from '../data/content/LinkFragment'
import s from './CarouselWithArtworks.module.sass'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { Icon } from './Icon'
import { Link } from './Link'

export type CarouselWithArtworkProps = {
	carousel: ArtworkSelectionResult
	isTitleVisible?: boolean
	link?: LinkResult
}

export const CarouselWithArtworks: FunctionComponent<CarouselWithArtworkProps> = ({
	carousel,
	isTitleVisible,
	link,
}) => {
	const cardElement = useRef<HTMLDivElement>(null)
	const cardsElement = useRef<HTMLDivElement>(null)

	const scroll = (direction: 1 | -1) => {
		if (cardsElement.current === null || cardElement.current === null) {
			return
		}

		const cardWidth = parseInt(getComputedStyle(cardElement.current).width.replace('px', ''))

		const scrollSlideOffset = direction * cardWidth

		cardsElement.current.scrollBy({
			left: scrollSlideOffset,
			behavior: 'smooth',
		})
	}

	const [showPreviousButton, setShowPreviousButton] = useState(false)
	const [showNextButton, setShowNextButton] = useState(false)

	const onSlideChange = () => {
		if (cardsElement.current === null) {
			return
		}
		setShowPreviousButton(true)
		setShowNextButton(true)
		if (cardsElement.current.scrollLeft === 0) {
			setShowPreviousButton(false)
		}
		if (
			cardsElement.current.scrollLeft ===
			(cardsElement.current.scrollWidth ?? 0) - (cardsElement.current.clientWidth ?? 0)
		) {
			setShowNextButton(false)
		}
	}

	useEffect(() => {
		if (cardsElement.current === null) {
			return
		}

		onSlideChange()

		window.addEventListener('resize', onSlideChange)
		return () => {
			window.removeEventListener('resize', onSlideChange)
		}
	}, [])
	return (
		<div className={s.CarouselWithArtworks}>
			<Container size="fullWidth" disableGutters>
				<Container size="fullWidth">
					<div className={s.TitleWrapper}>
						{isTitleVisible && <p className={s.Title}>{carousel.localesByLocale?.title}</p>}
						{link && (
							<Link link={link} className={s.ButtonDesktop}>
								{link.title}
							</Link>
						)}
					</div>
				</Container>
				<div className={s.CarouselArtworksWrapper}>
					<div className={s.CarouselArtworks} ref={cardsElement} onScroll={() => onSlideChange()}>
						{carousel.artworks?.items.map((item) => (
							<div key={item.id} className={s.ArtworkTile} ref={cardElement}>
								{item.artwork?.localesByLocale?.link?.url && (
									<Link href={item.artwork?.localesByLocale?.link?.url}>
										{item.artwork?.image && <ContemberImage image={item.artwork?.image} />}
										<div className={s.ArtworkTitle}>
											{item.artwork?.localesByLocale?.artist &&
												item.artwork.localesByLocale.artist + ', '}
											<div>{item.artwork?.localesByLocale?.title}</div>
										</div>
									</Link>
								)}
							</div>
						))}
						<div className={s.LastArtworkTile} ref={cardElement}></div>
					</div>
					<Container size="fullWidth">
						<div className={s.Arrows}>
							<button
								onClick={() => scroll(-1)}
								className={clsx(s.ArrowBackward, showPreviousButton && s.isVisible)}>
								<Icon name="arrowDown" />
							</button>
							<button
								onClick={() => scroll(1)}
								className={clsx(s.ArrowForward, showNextButton && s.isVisible)}>
								<Icon name="arrowDown" />
							</button>
						</div>
					</Container>
					<Container size="fullWidth">
						{link && (
							<Link link={link} className={s.ButtonMobile}>
								{link?.title}
							</Link>
						)}
					</Container>
				</div>
			</Container>
		</div>
	)
}
