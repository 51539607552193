import * as z from 'zod'
import { array, string } from 'zod'

export const NewsletterFormInput = () =>
	z.object({
		email: string().email(),
		newsletterTypes: array(string().uuid()).min(1),
	})

export type NewsletterFormInputData = z.infer<ReturnType<typeof NewsletterFormInput>>
