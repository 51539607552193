import React from 'react'
import s from './Breadcrumbs.module.sass'
import { Link } from './Link'

export type Breadcrumb = {
	url?: string | null
	title: string
}

export type BreadcrumbsProps = {
	breadcrumbs: Breadcrumb[]
}

export function Breadcrumbs(props: BreadcrumbsProps) {
	return (
		<>
			{props.breadcrumbs.map((breadcrumb, i) => {
				if (breadcrumb.url && i !== props.breadcrumbs.length - 1) {
					return (
						<React.Fragment key={i}>
							<span className={s.BreadcrumbTitle}>
								<Link href={breadcrumb.url}>{breadcrumb.title}</Link>
							</span>
							<div className={s.Dot}></div>
						</React.Fragment>
					)
				}
				return (
					<div className={s.BreadcrumbTitle} key={i}>
						{breadcrumb.title}
					</div>
				)
			})}
		</>
	)
}
