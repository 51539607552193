import * as z from 'zod'
import { number, string } from 'zod'

export const ContactFormInput = () =>
	z.object({
		name: string(),
		phone: string(),
		message: string(),
		email: string().email(),
		fileType: string().nullable().optional(),
		size: number().nullable().optional(),
	})

export type ContactFormInputData = z.infer<ReturnType<typeof ContactFormInput>>
