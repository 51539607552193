import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import type { ContactPageLocaleResult } from '../data/content/ContactPageLocaleFragment'
import s from './ContactPage.module.sass'
import { Container } from './Container'
import { Link } from './Link'

export type ContactProps = {
	contactPage: ContactPageLocaleResult
}

export function ContactPage(props: ContactProps) {
	return (
		<div className={s.ContactPage}>
			<Container size="normal">
				<h1 className={s.Title}>{props.contactPage.title}</h1>
				<div className={s.ContactSection}>
					<div>
						{props.contactPage.address && (
							<div className={s.Address}>
								<RichTextRenderer source={props.contactPage.address} />
							</div>
						)}
						{props.contactPage.openingHours && (
							<div className={s.OpeningHours}>
								<RichTextRenderer source={props.contactPage.openingHours} />
							</div>
						)}
					</div>
					<Link link={props.contactPage.addressLink} className={s.AddressLink} />
				</div>
				<div className={s.ContactSection}>
					{props.contactPage.phoneLabel && (
						<div className={s.PhoneLabel}>
							<RichTextRenderer source={props.contactPage.phoneLabel} />
						</div>
					)}
					{props.contactPage.phone && (
						<Link href={`tel:${props.contactPage.phone}`} className={s.Phone}>
							{props.contactPage.phone}
						</Link>
					)}
				</div>
				<div className={s.ContactSection}>
					{props.contactPage.emailLabel && (
						<div className={s.EmailLabel}>
							<RichTextRenderer source={props.contactPage.emailLabel} />
						</div>
					)}
					{props.contactPage.email && (
						<Link href={`mailto:${props.contactPage.email}`} className={s.Email}>
							{props.contactPage.email}
						</Link>
					)}
				</div>
				<div className={clsx(s.ContactSection, s.MobileTopLine)}>
					{props.contactPage.identificationsLabel && (
						<div className={s.EmailLabel}>
							<RichTextRenderer source={props.contactPage.identificationsLabel} />
						</div>
					)}
					<div className={s.IcoDicWrapper}>
						<p className={s.Ico}>{props.contactPage.ico}</p>
						<p className={s.Dic}>{props.contactPage.dic}</p>
					</div>
				</div>
				<div className={s.ContactSection}>
					{props.contactPage.databox && (
						<div className={s.DataBoxLabel}>
							<RichTextRenderer source={props.contactPage.databox} />
						</div>
					)}
					<div>
						{props.contactPage.databoxNumber && (
							<p className={s.DataBoxNumber}>{props.contactPage.databoxNumber}</p>
						)}
					</div>
				</div>
				<div className={s.ContactSection}>
					{props.contactPage.securities && (
						<div className={s.SecuritiesLabel}>
							<RichTextRenderer source={props.contactPage.securities} />
						</div>
					)}
					<div>
						{props.contactPage.securitiesNumber && (
							<p className={s.SecuritiesNumber}>{props.contactPage.securitiesNumber}</p>
						)}
					</div>
				</div>
				<div className={s.ContactSection}>
					{props.contactPage.socialsLabel && (
						<div className={s.EmailLabel}>
							<RichTextRenderer source={props.contactPage.socialsLabel} />
						</div>
					)}
					<div className={s.Socials}>
						{props.contactPage.instagram && (
							<Link href={props.contactPage.instagram} className={s.Instagram}>
								Instagram
							</Link>
						)}
						{props.contactPage.youtube && (
							<Link href={props.contactPage.youtube} className={s.Youtube}>
								Youtube
							</Link>
						)}
						{props.contactPage.facebook && (
							<Link href={props.contactPage.facebook} className={s.Facebook}>
								Facebook
							</Link>
						)}
						{props.contactPage.linkedIn && (
							<Link href={props.contactPage.linkedIn} className={s.LinkedIn}>
								LinkedIn
							</Link>
						)}
					</div>
				</div>
			</Container>
		</div>
	)
}
