import clsx from 'clsx'
import React, { RefObject, useEffect, useState } from 'react'
import i18n from '../../i18n'
import type { HeaderLocaleResult } from '../data/content/HeaderLocaleFragment'
import { filterNonEmpty } from '../utils/filterNonEmpty'
import { usePageLoadingState } from '../utils/usePageLoadingState'
import { Breadcrumb, Breadcrumbs } from './Breadcrumbs'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './Header.module.sass'
import { Icon } from './Icon'
import { Link } from './Link'

export type HeaderProps = {
	header: HeaderLocaleResult
	translations: {
		id: string
		link?: {
			url: string
		}
		locale?: {
			code: string
		}
	}[]
	isHomePage?: boolean
	logoProTargetRef: RefObject<HTMLDivElement>
	logoArteTargetRef: RefObject<HTMLDivElement>
	homePageLink?: string
	breadcrumbs?: Breadcrumb[]
}

export function Header(props: HeaderProps) {
	const [open, setOpen] = useState(false)
	const [loading] = usePageLoadingState()

	React.useEffect(() => {
		setOpen(false)
	}, [loading])

	const translations = props.translations.filter(
		(tr) => tr.locale?.code !== props.header.locale?.code
	)

	const existingLocales = props.translations.map((tr) => tr.locale?.code).filter(filterNonEmpty)

	const missingLocales = i18n.locales.filter(
		(locale) => !existingLocales.includes(locale) && locale !== props.header.locale?.code
	)

	const [scrollPosition, setScrollPosition] = useState(0)

	useEffect(() => {
		const calculatePosition = () => {
			setScrollPosition(window.scrollY)
		}
		window.addEventListener('scroll', calculatePosition, {
			passive: true,
		})
		return () => {
			window.removeEventListener('scroll', calculatePosition)
		}
	}, [])

	return (
		<div
			className={clsx(
				s.HeaderSticky,
				open && s.isHeaderOpen,
				props.isHomePage && s.isHomePageBorder,
				scrollPosition > 600 && s.isHomePageBorderVisible
			)}>
			<Container size="fullWidth">
				<div className={clsx(s.Header, props.isHomePage && s.isHomePage)}>
					<div className={s.Breadcrumbs}>
						{props.breadcrumbs && <Breadcrumbs breadcrumbs={props.breadcrumbs} />}
					</div>
					<div className={s.Logo}>
						{props.header.root?.logo && (
							<Link href={props.homePageLink ?? '/'}>
								<ContemberImage image={props.header.root.logo} layout="intrinsic" />
							</Link>
						)}
					</div>
					{props.isHomePage && (
						<Link href={props.homePageLink ?? '/'} className={s.HomePageLogo}>
							<div ref={props.logoProTargetRef} className={s.Pro}></div>
							<div ref={props.logoArteTargetRef} className={s.Arte}></div>
						</Link>
					)}
					<button className={s.IconHamburger} onClick={() => setOpen((old) => !old)}>
						<Icon name="hamburger" />
					</button>
					<div className={s.IconHamburgerMobileWrapper}>
						<button className={s.IconHamburgerMobile} onClick={() => setOpen((old) => !old)}>
							<Icon name="hamburgerMobile" />
						</button>
					</div>
					<div className={clsx(s.HeaderOpen, open && s.isActive)}>
						<Container size="fullWidth">
							<div className={s.Header}>
								<div className={s.Locales}>
									<div className={s.LocaleWrapper}>
										<div className={clsx(s.Locale, s.isActiveLocale)}>
											{props.header.locale?.code}
										</div>
									</div>
									{translations.map((item) => (
										<div key={item.id} className={s.LocaleWrapper}>
											<div className={s.Dot}></div>
											<a
												href={item.link?.url}
												onClick={() => {
													document.cookie = `NEXT_LOCALE=${item.locale?.code}; path=/`
												}}
												className={s.Locale}>
												{item.locale?.code}
											</a>
										</div>
									))}
									{missingLocales.map((locale, i) => (
										<div key={i} className={s.MissingLocale}>
											<a
												className={s.Locale}
												onClick={() => {
													document.cookie = `NEXT_LOCALE=${locale}; path=/`
												}}
												href={locale === i18n.defaultLocale ? '/' : `/${locale}`}>
												{locale}
											</a>
										</div>
									))}
								</div>
								<div className={s.Logo}>
									{props.header.root?.logo && (
										<Link href="/">
											<ContemberImage image={props.header.root.logo} layout="intrinsic" />
										</Link>
									)}
								</div>
								<button
									className={clsx(s.IconHamburger, s.Close, open && s.isActive)}
									onClick={() => setOpen((old) => !old)}>
									<Icon name="cross" />
								</button>
							</div>

							<div className={clsx(s.Links, s.MobileLinks, open && s.isActive)}>
								{props.header.links.map((item) => (
									<Link
										key={item.id}
										link={item.link}
										className={s.Link}
										activeClassName={s.isActiveLink}>
										<p className={s.LinkTitle}>{item.link?.title}</p>
										<div className={s.Arrow}>
											<Icon name="arrowDown" />
										</div>
									</Link>
								))}
							</div>
							<button
								className={clsx(s.IconHamburger, s.CloseMobile, open && s.isActive)}
								onClick={() => setOpen((old) => !old)}>
								<Icon name="cross" />
							</button>
						</Container>
					</div>
				</div>
			</Container>
		</div>
	)
}
