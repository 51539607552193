import type { FunctionComponent } from 'react'
import type { EventListResult } from '../data/content/EventListFragment'
import { BoxTile } from './BoxTile'
import { Container } from './Container'
import s from './EventList.module.sass'

export type EventListProps = {
	events: EventListResult
	eventType: string
}

export const EventList: FunctionComponent<EventListProps> = ({ events, eventType }) => {
	return (
		<div className={s.EvetList}>
			<Container size="normal">
				{events.items.map(
					(item) =>
						item.event?.localesByLocale?.link && (
							<BoxTile
								key={item.id}
								link={item.event.localesByLocale.link.url}
								date={item.event.localesByLocale.date}
								type={eventType}
								title={item.event.localesByLocale.title}
								text={item.event.localesByLocale.leadParagraph}
							/>
						)
				)}
			</Container>
		</div>
	)
}
