import type { FunctionComponent } from 'react'
import type { LogoListResult } from '../data/content/LogoListFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './LogoList.module.sass'

export type LogoListProps = {
	logoList: LogoListResult
}

export const LogoList: FunctionComponent<LogoListProps> = ({ logoList }) => {
	return (
		<Container size="normal">
			<div className={s.LogoList}>
				{logoList.items.map(
					(item) =>
						item.image && (
							<div key={item.id} className={s.ImageWrapper}>
								<div key={item.id} className={s.Image}>
									<ContemberImage image={item.image} layout="fill" objectFit="contain" />
								</div>
							</div>
						)
				)}
			</div>
		</Container>
	)
}
