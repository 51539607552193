import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { Collapsible } from 'collapsible-react-component'
import 'collapsible-react-component/dist/index.css'
import { Fragment, FunctionComponent, useState } from 'react'
import type { DropDownListResult } from '../data/content/DropDownListFragment'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import s from './DropDown.module.sass'
import { Icon } from './Icon'
import { Link } from './Link'

export type DropDownProps = {
	dropDownList: DropDownListResult
}

export const Dropdown: FunctionComponent<DropDownProps> = ({ dropDownList }) => {
	return (
		<Container size="normal">
			<div className={s.DropDown}>
				{dropDownList.dropDownType === 'link' &&
					dropDownList.items.map((item) => (
						<Fragment key={item.id}>
							<DropdownLinkItem {...item} />
						</Fragment>
					))}
				{dropDownList.dropDownType === 'person' &&
					dropDownList.employees?.items.map((item) => (
						<Fragment key={item.id}>
							<DropdownPersonItem {...item} />
						</Fragment>
					))}
			</div>
		</Container>
	)
}

export const DropdownLinkItem: FunctionComponent<
	DropDownProps['dropDownList']['items'][number]
> = ({ ...item }) => {
	const [open, setOpen] = useState(false)

	return (
		<div className={clsx(s.DropDownItem, open && s.isOpen)}>
			<button onClick={() => setOpen((old) => !old)} className={s.Title}>
				{item.title}
				<div className={s.Arrow}>
					<Icon name="arrowDown" />
				</div>
			</button>
			<Collapsible open={open}>
				<div className={s.DropDownContent}>
					{item.text && (
						<div className={s.Text}>
							<RichTextRenderer source={item.text} />
						</div>
					)}
					<div className={s.ButtonWrapper}>
						<Link link={item.link} className={s.Button} />
					</div>
				</div>
			</Collapsible>
		</div>
	)
}

export const DropdownPersonItem: FunctionComponent<
	NonNullable<DropDownProps['dropDownList']['employees']>['items'][number]
> = ({ ...item }) => {
	const [open, setOpen] = useState(false)

	return (
		<div className={clsx(s.DropDownItem, open && s.isOpen)}>
			<button onClick={() => setOpen((old) => !old)} className={s.Title}>
				{item.employee?.name} {'–'} {item.employee?.localesByLocale?.position}
				<div className={s.Arrow}>
					<Icon name="arrowDown" />
				</div>
			</button>
			<Collapsible open={open}>
				<div className={s.DropDownContent} key={item.id}>
					{item.employee?.image && (
						<div className={s.Image}>
							<ContemberImage image={item.employee?.image} />
						</div>
					)}
					{item.employee?.localesByLocale?.text && (
						<p className={s.Text}>
							<RichTextRenderer source={item.employee?.localesByLocale?.text} />
						</p>
					)}
					<div className={s.ButtonWrapper}>
						{item.employee?.localesByLocale?.email && (
							<Link className={s.Button} href={`mailto:${item.employee?.localesByLocale?.email}`}>
								{item.employee?.localesByLocale?.email}
							</Link>
						)}
						{item.employee?.localesByLocale?.phone && (
							<Link
								className={s.ButtonSecond}
								href={`tel:to${item.employee?.localesByLocale?.phone}`}>
								{item.employee?.localesByLocale?.phone}
							</Link>
						)}
					</div>
				</div>
			</Collapsible>
		</div>
	)
}
