import { RichTextRenderer } from '@contember/react-client'
import { LightboxSource, useLightbox } from '@mangoweb/react-fslightbox'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import type { ArtworkLocaleResult } from '../data/content/ArtworkLocaleFragment'
import { filterNonEmpty } from '../utils/filterNonEmpty'
import s from './Artwork.module.sass'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { ContentRenderer } from './ContentRenderer'
import { Icon } from './Icon'
export type ArtworkProps = {
	artwork: ArtworkLocaleResult
}

export function Artwork(props: ArtworkProps) {
	const cardElement = useRef<HTMLDivElement>(null)
	const cardsElement = useRef<HTMLDivElement>(null)

	const galleryImages: LightboxSource[] =
		props.artwork.root?.gallery?.items
			.map((item): LightboxSource | undefined => ({
				source: item.image?.url ?? '',
				type: 'image',
			}))
			.filter(filterNonEmpty) || []

	const count = galleryImages.length

	const lightbox = useLightbox(galleryImages)

	const scroll = (direction: 1 | -1) => {
		if (cardsElement.current === null || cardElement.current === null) {
			return
		}

		const cardWidth = parseInt(getComputedStyle(cardElement.current).width.replace('px', ''))

		const scrollSlideOffset = direction * cardWidth

		cardsElement.current.scrollBy({
			left: scrollSlideOffset,
			behavior: 'smooth',
		})
	}

	const [activeIndex, setActiveIndex] = useState(0)

	const [showPreviousButton, setShowPreviousButton] = useState(false)
	const [showNextButton, setShowNextButton] = useState(false)

	const onSlideChange = () => {
		if (cardsElement.current === null) {
			return
		}

		const percent =
			cardsElement.current.scrollLeft /
			(cardsElement.current.scrollWidth - cardsElement.current.clientWidth)
		const active = (count - 1) * percent
		setActiveIndex(Math.round(active))

		setShowPreviousButton(true)
		setShowNextButton(true)
		if (cardsElement.current.scrollLeft === 0) {
			setShowPreviousButton(false)
		}
		if (
			cardsElement.current.scrollLeft ===
			(cardsElement.current.scrollWidth ?? 0) - (cardsElement.current.clientWidth ?? 0)
		) {
			setShowNextButton(false)
		}
	}

	useEffect(() => {
		if (cardsElement.current === null) {
			return
		}

		onSlideChange()

		window.addEventListener('resize', onSlideChange)
		return () => {
			window.removeEventListener('resize', onSlideChange)
		}
	})

	return (
		<div className={s.Artwork}>
			<Container size="fullWidth" disableGutters>
				{props.artwork.root?.gallery?.items.length ? (
					<div className={s.GalleryWrapper}>
						<div className={s.Gallery} ref={cardsElement} onScroll={() => onSlideChange()}>
							{props.artwork.root.gallery?.items.map((item, index) => (
								<div key={item.id} ref={cardElement} className={s.GalleryItem}>
									{item.image && (
										<button onClick={() => lightbox?.open(index)} className={s.GalleryButton}>
											<div className={s.GalleryImage}>
												<ContemberImage image={item.image} objectFit="contain" />
											</div>
										</button>
									)}
								</div>
							))}
							<div className={s.LastGalleryItem} ref={cardElement}></div>
						</div>
					</div>
				) : (
					props.artwork.root?.image && (
						<div className={s.ImageWrapper}>
							<div className={s.Image}>
								<ContemberImage
									image={props.artwork.root.image}
									layout="fill"
									objectFit="contain"
								/>
							</div>
						</div>
					)
				)}
			</Container>
			<Container size="fullWidth">
				<div className={s.ArrowWrapper}>
					<div className={s.ArrowSection}></div>
					<button
						className={clsx(s.FullScreenButton, s.ArrowSection)}
						onClick={() => lightbox?.open(activeIndex)}>
						Prozkoumat dílo
					</button>
					<div className={clsx(s.Arrows, s.ArrowSection)}>
						<button
							onClick={() => scroll(-1)}
							className={clsx(s.ArrowBackward, showPreviousButton && s.isVisible)}>
							<Icon name="arrowDown" />
						</button>
						<button
							onClick={() => scroll(1)}
							className={clsx(s.ArrowForward, showNextButton && s.isVisible)}>
							<Icon name="arrowDown" />
						</button>
					</div>
				</div>
			</Container>
			<Container size="normal">
				<h1 className={s.Title}>
					<div>{props.artwork.artist}</div>
					<div>{props.artwork.title}</div>
				</h1>

				{props.artwork.label && (
					<div className={s.Label}>
						<RichTextRenderer source={props.artwork.label} />
					</div>
				)}
				{props.artwork.leadParagraph && (
					<div className={s.LeadParagraph}>
						<RichTextRenderer source={props.artwork.leadParagraph} />
					</div>
				)}
			</Container>

			{props.artwork.content && <ContentRenderer content={props.artwork.content} />}
		</div>
	)
}
